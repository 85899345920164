import { useLayoutEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from '@reach/router'
import { getEnv } from "@urecruits/api"
import NotificationsPopup from './Notifications'
import { requestFCMToken } from '../util/requestNotification'
import axios from 'axios'
import { ShowToolTipComponent, useHasPermission } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import { AuthGuard } from '@ucrecruits/globalstyle/components/AuthProvider'

const logoIcon = require('../image/icon/compact-logo.svg')
const avatarIcon = require('../image/avatar.svg')
const bellIcon = require('../image/icon/bell_ic.svg')

const feedbackIcon = require('../image/icon/feedback_ic.svg')
const logoutIcon = require('../image/icon/logout_ic.svg')
const settingIcon = require('../image/icon/settings_ic.svg')
const userIcon = require('../image/icon/user_ic.svg')


const { API_RECRUITMENT } = getEnv()
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};


const Header = ({ mobileMenu, setMobile }) => {
  const [userSetting, setUserSetting] = useState(false)
  const [notifications, setNotifications] = useState(false)
  const [newNotificationCount, setNewNotificationCount] = useState(0)
  const { logout } = useAuth0()
  const [profilePicture, setProfilePicture] = useState("");
  const { checkUserPermission } = useHasPermission();
  useLayoutEffect(() => {
    axios.get(`${API_RECRUITMENT}/api/notifications?read=false`, config).then(data => {
      setNewNotificationCount(data.data?.count)
    })
  }, []);
  useLayoutEffect(() => {
    async function getProfilePhoto() {
      if (checkUserPermission('company-owner')) {
        await axios.get(`${API_RECRUITMENT}/api/company`, config).then(data => { setProfilePicture(data.data?.avatar) })
      } else {
        await axios.get(`${API_RECRUITMENT}/api/user`, config).then(data => setProfilePicture(data.data?.avatar))
      }
    }
    getProfilePhoto();
  }, [checkUserPermission('company-owner')])


  const logoutBtn = () => {
    localStorage.clear();
    logout({ returnTo: "https://urecruits.com/api/auth/logout" })
  }

  return (
    <div className="header__inner">
      <Link to="/">
        <div className="header__logo">
          <img src={logoIcon} alt="logo" className="header__logo__icon" />
        </div>
      </Link>
      <div className="header__left">
        <div
          className={`${mobileMenu ? "header__burger active" : "header__burger"
            }`}
          onClick={() => {
            setUserSetting(false);
            setMobile(!mobileMenu);
          }}
        >
          <span />
          <span />
          <span />
        </div>
        <Link to="/" className="header__left__link">
          Home
        </Link>
      </div>
      <nav className="header__nav">
        {checkUserPermission("candidate") ? (
          <ul className="header__nav__list">
            <li className="header__nav__item">
              <p className="header__nav__name">Job Search</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
              <ul className="header__sublist">
                <div className="header__sublist__inner">
                  {/* <li className="header__sublist__item">
                    <Link
                      to="/recruitment/home"
                      className="header__sublist__link"
                    >
                      Home
                    </Link>
                  </li> */}
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/jobs"
                      className="header__sublist__link"
                    >
                      Jobs
                    </Link>
                  </li>
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/companies"
                      className="header__sublist__link"
                    >
                      Companies
                    </Link>
                  </li>
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/jobs?type=match"
                      className="header__sublist__link"
                    >
                      Matched jobs
                    </Link>
                  </li>
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/jobs?type=save"
                      className="header__sublist__link"
                    >
                      Saved jobs
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
            <li className="header__nav__item">
              <p className="header__nav__name">Assessment</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
              <ul className="header__sublist">
                <div className="header__sublist__inner">
                  {/* <li className="header__sublist__item">
                    <Link
                      to="/candidate/assessments"
                      className="header__sublist__link"
                    >
                      Home
                    </Link>
                  </li> */}
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/scoreboard"
                      className="header__sublist__link"
                    >
                      Scoreboard
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
            <li className="header__nav__item">
              <p className="header__nav__name">Screening & Hiring</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
              <ul className="header__sublist">
                <div className="header__sublist__inner">
                  {/* <li className="header__sublist__item">
                    <Link
                      to="/screening-hiring/home"
                      className="header__sublist__link"
                    >
                      Home
                    </Link>
                  </li> */}
                  <li className="header__sublist__item">
                    <Link
                      to="/candidate/offers"
                      className="header__sublist__link"
                    >
                      Offers
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        ) : (
          <ul className="header__nav__list">
            <li className="header__nav__item">
              <p className="header__nav__name">dashboard</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
              <ul className="header__sublist">
                <div className="header__sublist__inner">
                  <li className="header__sublist__item">
                    <Link to="/" className="header__sublist__link">
                      Home
                    </Link>
                    <Link
                      to="/recruitment/mail-box"
                      className="header__sublist__link"
                    >
                      Mail Box
                    </Link>
                    {/* <Link to="/" className="header__sublist__link">
                      Calendar
                    </Link> */}
                    <AuthGuard module='job-post' permission={["view", "add", "edit"]}>
                      <Link
                        to="/recruitment/jobs"
                        className="header__sublist__link"
                      >
                        Jobs
                      </Link>
                    </AuthGuard>
                    {/* <Link to="/" className="header__sublist__link">
                      Files
                    </Link> */}
                  </li>
                </div>
              </ul>
            </li>
            <li className="header__nav__item">
              <p className="header__nav__name">recruitment</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
              <ul className="header__sublist">
                <div className="header__sublist__inner">
                  <li className="header__sublist__item">
                    <Link to="/recruitment/home" className="header__sublist__link">
                      Home
                    </Link>
                  </li>
                  <AuthGuard module='job-post' permission='add'>
                    <li className="header__sublist__item">
                      <Link to="/job/create" className="header__sublist__link">
                        Create New Job
                      </Link>
                    </li>
                  </AuthGuard>
                  <AuthGuard module='team-members' permission={['view', 'add', "edit"]}>
                    <li className="header__sublist__item">
                      <Link
                        to="/recruitment/manage-team-members"
                        className="header__sublist__link"
                      >
                        Team Members
                      </Link>
                    </li>
                  </AuthGuard>
                  <AuthGuard module='job-post' permission={["add", "edit", "view"]}>
                    <li className="header__sublist__item">
                      <Link
                        to="/recruitment/position-workflow"
                        className="header__sublist__link"
                      >
                        Workflows
                      </Link>
                    </li>
                  </AuthGuard>
                  {/*<li className="header__sublist__item">*/}
                  {/*  <Link*/}
                  {/*    to="/screening-questions"*/}
                  {/*    className="header__sublist__link"*/}
                  {/*  >*/}
                  {/*    Screening questions*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <AuthGuard module='job-post' permission={["view", "add", "edit"]}>
                    <li className="header__sublist__item">
                      <Link
                        to="/recruitment/jobs"
                        className="header__sublist__link"
                      >
                        Jobs
                      </Link>
                    </li>
                  </AuthGuard>
                  <li className="header__sublist__item">
                    <Link
                      to="/recruitment/candidate-profile"
                      className="header__sublist__link"
                    >
                      Candidates
                    </Link>
                  </li>
                </div>
              </ul>
            </li>
            <AuthGuard module='assessment' permission={["view", "add", "edit"]}>
              <li className="header__nav__item">
                <p className="header__nav__name">Assessments</p>
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="header__nav__item--arrow"
                >
                  <path
                    d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                    fill="#B0BBCB"
                  />
                </svg>
                <ul className="header__sublist">
                  <div className="header__sublist__inner">
                    <li className="header__sublist__item">
                      <Link
                        to="/recruitment/assessments"
                        className="header__sublist__link"
                      >
                        Home
                      </Link>
                      <AuthGuard module={['job-post', 'assessment']} permission='view' option='AND'>
                        <AuthGuard module='assessment' permission={['edit', 'add', 'delete']} >
                          <Link
                            to="/recruitment/manage-assignment"
                            className="header__sublist__link"
                          >
                            Assignments
                          </Link>
                        </AuthGuard>
                      </AuthGuard>
                    </li>
                    <li className="header__sublist__item">
                      <Link to="/assessment/domain-assessment" className="header__sublist__link">
                        Domain Assessments
                      </Link>
                    </li>
                    <li className="header__sublist__item">
                      <Link
                        to="/coding-assessments"
                        className="header__sublist__link"
                      >
                        Coding Assessments
                      </Link>
                    </li>
                    {/* <li className="header__sublist__item">
                    <Link to="/databases" className="header__sublist__link">
                      Databases
                    </Link>
                  </li> */}
                    <AuthGuard module={['job-post', 'assessment']} permission='view' >
                      <li className="header__sublist__item">
                        <Link
                          to="/recruitment/review-score"
                          className="header__sublist__link"
                        >
                          Review & Score
                        </Link>
                      </li>
                    </AuthGuard>
                    <AuthGuard module='job-post' permission='view'>
                      <li className="header__sublist__item">
                        <Link
                          to="/recruitment/scoreboard"
                          className="header__sublist__link"
                        >
                          Scoreboard
                        </Link>
                      </li>
                    </AuthGuard>
                  </div>
                </ul>
              </li>
            </AuthGuard>
            <AuthGuard module='job-post' permission='view'>
              <li className="header__nav__item">
                <p className="header__nav__name">Screening & Hiring</p>
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="header__nav__item--arrow"
                >
                  <path
                    d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                    fill="#B0BBCB"
                  />
                </svg>
                <ul className="header__sublist">
                  <div className="header__sublist__inner">
                    <li className="header__sublist__item">
                      <Link
                        to="/recruitment/screening-hiring"
                        className="header__sublist__link"
                      >
                        Home
                      </Link>
                    </li>
                    <AuthGuard module='job-post' permission={["view", "edit"]}>
                      <li className="header__sublist__item">
                        <Link
                          to="/recruitment/interviews/jobs"
                          className="header__sublist__link"
                        >
                          Interviews
                        </Link>
                      </li>
                    </AuthGuard>
                    <AuthGuard module={['offer', "job-post"]} permission={'view'} option='AND'>
                      <AuthGuard module={'offer'} permission={["delete", "add", "edit"]}>
                        <li className="header__sublist__item">
                          <Link
                            to="/recruitment/offers"
                            className="header__sublist__link"
                          >
                            Offers
                          </Link>
                        </li>
                      </AuthGuard>
                    </AuthGuard>
                    <AuthGuard module={['background', "job-post"]} permission={'view'} option='AND'>
                      <AuthGuard module={'background'} permission={["delete", "add", "edit"]}>
                        <li className="header__sublist__item">
                          <Link
                            to="/recruitment/background-screening/jobs"
                            className="header__sublist__link"
                          >
                            Background Screening
                          </Link>
                        </li>
                      </AuthGuard>
                    </AuthGuard>
                    <AuthGuard module={['drug', "job-post"]} permission={'view'} option='AND'>
                      <AuthGuard module={'drug'} permission={["delete", "add", "edit"]}>
                        <li className="header__sublist__item">
                          <Link
                            to="/recruitment/drug-screening/jobs"
                            className="header__sublist__link"
                          >
                            Drug Screening
                          </Link>
                        </li>
                      </AuthGuard>
                    </AuthGuard>
                    {/* <li className="header__sublist__item">
                    <Link to="/" className="header__sublist__link">
                      Scoreboard
                    </Link>
                  </li> */}
                    {/* <li className="header__sublist__item">
                    <Link to="/" className="header__sublist__link">
                      Integration
                    </Link>
                  </li> */}
                  </div>
                </ul>
              </li>
            </AuthGuard>
            {/* <li className="header__nav__item">
              <p className="header__nav__name">HR Analytics</p>
              <svg
                width="10"
                height="7"
                viewBox="0 0 10 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="header__nav__item--arrow"
              >
                <path
                  d="M5.00001 0.375H0.987443C0.207901 0.375 -0.182497 1.3175 0.368723 1.86872L4.38129 5.88128C4.723 6.22299 5.27701 6.22299 5.61872 5.88128L9.63129 1.86872C10.1825 1.3175 9.79211 0.375 9.01257 0.375H5.00001Z"
                  fill="#B0BBCB"
                />
              </svg>
            </li> */}
          </ul>
        )}
      </nav>
      <div className="header__action">
        <div className="header__notification">
          <div onClick={() => {
            requestFCMToken()
            setNotifications(!notifications)
            mobileMenu && setMobile(!mobileMenu)
            if (userSetting) {
              setUserSetting(!userSetting)
            }
          }}>

            {newNotificationCount > 0 && <span className='header__notification__count'>{newNotificationCount}</span>}
            <ShowToolTipComponent text='Notifications'>

              <img
                src={bellIcon}
                alt="notification icon"
                className="header__notification--icon"
              />
            </ShowToolTipComponent>
          </div>
        </div>
        {notifications && (
          <NotificationsPopup setCount={setNewNotificationCount} />
        )}
        <div className="header__user">
          <div
            className="header__user__head"
            onClick={() => {
              if (notifications) {
                setNotifications(!notifications)
              }
              mobileMenu && setMobile(!mobileMenu)
              setUserSetting(!userSetting)
            }}
          >
            <ShowToolTipComponent text='Profile'>
              {profilePicture ?
                <img
                  src={profilePicture}
                  alt="user profile"
                  className="header__user__avatar"
                />
                : <img
                  src={avatarIcon}
                  alt="user profile"
                  className="header__user__avatar"
                />}
            </ShowToolTipComponent>
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="header__user__arrow"
            >
              <path
                d="M7 0.25H1.26777C0.154135 0.25 -0.403575 1.59643 0.383881 2.38388L6.11612 8.11612C6.60427 8.60427 7.39573 8.60427 7.88388 8.11612L13.6161 2.38388C14.4036 1.59643 13.8459 0.25 12.7322 0.25H7Z"
                fill="#737980"
              />
            </svg>
          </div>
          {userSetting && (
            <ul className="header__setting">
              <li className="header__setting__item">
                <Link
                  to="/profile-setting"
                  className="header__setting__link"
                  onClick={() => setUserSetting(false)}
                >
                  <p className="header__setting__name">Profile</p>
                  <img
                    src={userIcon}
                    alt="setting icon"
                    className="header__setting__icon"
                  />
                </Link>
              </li>
              <li className="header__setting__item">
                <Link
                  to="/"
                  className="header__setting__link"
                  onClick={() => setUserSetting(false)}
                >
                  <p className="header__setting__name">My Feedback</p>
                  <img
                    src={feedbackIcon}
                    alt="setting icon"
                    className="header__setting__icon"
                  />
                </Link>
              </li>
              <li className="header__setting__item">
                <Link
                  to="/"
                  className="header__setting__link"
                  onClick={() => setUserSetting(false)}
                >
                  <p className="header__setting__name">Settings</p>
                  <img
                    src={settingIcon}
                    alt="setting icon"
                    className="header__setting__icon"
                  />
                </Link>
              </li>
              <li className="header__setting__item">
                <p
                  className="header__setting__link"
                  onClick={() => logoutBtn()}
                >
                  <p className="header__setting__name">Log Out</p>
                  <img
                    src={logoutIcon}
                    alt="setting icon"
                    className="header__setting__icon"
                  />
                </p>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header
