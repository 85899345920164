import { useState } from 'react'
import { Link } from '@reach/router'

const MobileMenuItem = ({setMobile, item}) => {
  const [displaySubmenu, setDisplaySubmenu] = useState(false)

  return (
    <li className={`mobile-menu__item ${displaySubmenu ? 'active' : ''}`}>
      <div className="mobile-menu__item__head"
           onClick={() => setDisplaySubmenu(!displaySubmenu)}
      >
        <p className="mobile-menu__item__name">
          {item.title}
        </p>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="mobile-menu__item__icon">
          <path
            d="M4.99869 0.375H0.98613C0.206589 0.375 -0.183809 1.3175 0.36741 1.86872L4.37998 5.88128C4.72168 6.22299 5.2757 6.22299 5.61741 5.88128L9.62998 1.86872C10.1812 1.3175 9.7908 0.375 9.01126 0.375H4.99869Z"
            fill="#B0BBCB"/>
        </svg>
      </div>
      {
        displaySubmenu && (
          <ul className="mobile-menu__sublist">
            {
             item.submenu.map((item, index) => {
               return(
                 <li className="mobile-menu__sublist__item" key={index}>
                   <Link to={item.to} className="mobile-menu__sublist__link" onClick={() => setMobile(false)}>
                     <img src={item.icon} alt="submenu icon" className="mobile-menu__sublist__icon"/>
                     <p className="mobile-menu__sublist__name">
                       {item.label}
                     </p>
                   </Link>
                 </li>
               )
             })
            }
          </ul>
        )
      }
    </li>
  )
}

export default MobileMenuItem