import {messaging} from './firebase'
import axios from "axios";
import {getEnv} from '@urecruits/api'
const {API_RECRUITMENT} = getEnv();
const token= localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

const requestFCMToken = async ()=>{
  try{
    const permission = await Notification.requestPermission()
			if(permission === 'granted'){
				const registration = await navigator.serviceWorker.ready;
				const token = await messaging.getToken( {
					vapidKey:
					"BF4l_Qd_Xk7kp0IbNHDGvD-pGNG0fpNr0GVVcOz9LiW4KDvjyR53buJM4x8PNOjdTNVN0rfIUWzypg1YQE4YHkQ",
					serviceWorkerRegistration: registration,
				});
				console.log("Gen Token",token)
				if(token && localStorage.getItem('notifyToken') !== token){
					const notificationToken = await axios.post(`${API_RECRUITMENT}/api/notify-permissions`,{notifyPermissionsToken:token},config)
					localStorage.setItem('notifyToken',token)
				}

			}else if(permission==='denied'){
				localStorage.removeItem('notifyToken')
			}
      return permission;
  }catch(error){
    console.log("Error:",error)
  }
}

export {requestFCMToken}