import '../styles/main.scss';
import { useState } from 'react';
import MobileMenu from '../components/MobileMenu';
import { Auth0Provider } from "@auth0/auth0-react";
import LoginRedirect from "../components/Login";
import Header from "../components/Header";

export default function Root (props) {
  const [mobileMenu, setMobile] = useState(false)

  return (
    <Auth0Provider
      // domain="dev-9zt22me9.us.auth0.com"
      domain="auth.urecruits.com"
      clientId="kFkMzLszJ7QoTpFhSlvYJWeuAAGyiNCc"
      redirectUri={window.location.origin}
      audience="https://api-authz.urecruits.com/"
      cacheLocation="localstorage"
      // useRefreshToken={true}
      scope='openid profile email offline_access'
    >
      <LoginRedirect/>
      <Header mobileMenu={mobileMenu} setMobile={setMobile}/>
      <MobileMenu mobileMenu={mobileMenu} setMobile={setMobile}/>
    </Auth0Provider>
  )
}
